const styles = (theme) => ({
  root: theme.mixins.gutters({
    padding: theme.spacing(3),
    color: theme.palette.text.primary,
    borderRadius: theme.rounded.medium,
    boxShadow: "1px 1px 12px 0px #00000040",
    minHeight: "calc(100vh - 161px)",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
    },
  }),
  iconStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconButton: {
    padding: 0,
    minWidth: 0,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "16px",
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "8px",
    },
  },
  headingBorder: {
    paddingBottom: 8,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
  descriptionMargin: {
    marginBottom: 22,
  },
  hideHeading: {
    display: "none",
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: "normal",
    fontStyle: "normal",
    color: "black",
    marginBottom: theme.spacing(1),
    "&::first-letter": {
      textTransform: "capitalize",
    },
  },
  description: {
    color: "#8B909A",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "142.857%",
    letterSpacing: "-0.28px",
  },
  // don't know for what this is used for yet
  colorMode: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
    "& $title": {
      color: theme.palette.grey[100],
      "&:after": {
        borderBottom: `5px solid ${theme.palette.primary.light}`,
      },
    },
    "& $description": {
      color: theme.palette.grey[100],
    },
  },
  headingExtraContent: {
    display: "flex",
    justifyContent: "flex-end",
    flex: 1,
    zIndex: 2,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
});

export default styles;
