import Loading from "components/Loading";
import Loadable from "react-loadable";

export const BlankPage = Loadable({
  loader: () => import("./Pages/BlankPage"),
  loading: Loading,
});
export const ChatPage = Loadable({
  loader: () => import("./Pages/Chat/ChatPage"),
  loading: Loading,
});
export const ChatServicesWidget = Loadable({
  loader: () => import("./Pages/ChatServicesWidget/ChatServicesWidget"),
  loading: Loading,
});
export const ChatSettings = Loadable({
  loader: () => import("./Pages/ChatSettings/ChatSettings"),
  loading: Loading,
});
export const DashboardPage = Loadable({
  loader: () => import("./Pages/Dashboard"),
  loading: Loading,
});
export const Organization = Loadable({
  loader: () => import("./Pages/Forms/Organization"),
  loading: Loading,
});
export const OrganizationDomains = Loadable({
  loader: () => import("./Pages/Table/OrganizationDomains"),
  loading: Loading,
});
export const CreateBrand = Loadable({
  loader: () => import("./Pages/Forms/CreateBrand/CreateBrand"),
  loading: Loading,
});
export const UpdateBrand = Loadable({
  loader: () => import("./Pages/Forms/UpdateBrand"),
  loading: Loading,
});
export const OrganizationImage = Loadable({
  loader: () => import("./Pages/Forms/OrganizationImage"),
  loading: Loading,
});
export const CreateShop = Loadable({
  loader: () => import("./Pages/Forms/CreateShop"),
  loading: Loading,
});
export const UpdateShop = Loadable({
  loader: () => import("./Pages/Forms/UpdateShop"),
  loading: Loading,
});
export const CreateCategory = Loadable({
  loader: () => import("./Pages/Forms/CreateCategory"),
  loading: Loading,
});
export const UpdateCategory = Loadable({
  loader: () => import("./Pages/Forms/UpdateCategory"),
  loading: Loading,
});
export const CreateTagsTree = Loadable({
  loader: () => import("./Pages/Forms/TagsTree"),
  loading: Loading,
});
export const TagsTree = Loadable({
  loader: () => import("./Pages/Listing/TagsTree/TagsTreeTable"),
  loading: Loading,
});
export const ShopSections = Loadable({
  loader: () => import("./Pages/Listing/ShopSections"),
  loading: Loading,
});
export const Shop360Bulk = Loadable({
  loader: () => import("./Pages/Forms/Shop360Bulk"),
  loading: Loading,
});
export const Shop360Editor = Loadable({
  loader: () => import("./Pages/Standalone/Shop360Editor"),
  loading: Loading,
});
export const OrganizationInfo = Loadable({
  loader: () => import("./Pages/Forms/OrganizationInfo"),
  loading: Loading,
});
export const GenerateSiteMap = Loadable({
  loader: () => import("./Pages/Forms/GenerateSiteMap"),
  loading: Loading,
});
export const CreateUser = Loadable({
  loader: () => import("./Pages/Forms/CreateUser"),
  loading: Loading,
});
export const Customers = Loadable({
  loader: () => import("./Pages/Table/Customers"),
  loading: Loading,
});
export const Employees = Loadable({
  loader: () => import("./Pages/Table/Employees"),
  loading: Loading,
});
export const SubscribedUsers = Loadable({
  loader: () => import("./Pages/Table/SubscribedUsers"),
  loading: Loading,
});
export const Brands = Loadable({
  loader: () => import("./Pages/Listing/Brands/Brands"),
  loading: Loading,
});
export const Tags = Loadable({
  loader: () => import("./Pages/Listing/Tags"),
  loading: Loading,
});
export const Collections = Loadable({
  loader: () => import("./Pages/Table/Collections"),
  loading: Loading,
});
export const Products = Loadable({
  loader: () => import("./Pages/EditProduct/EditProduct"),
  loading: Loading,
});
export const Shops = Loadable({
  loader: () => import("./Pages/Listing/Shops"),
  loading: Loading,
});
export const SubAreas = Loadable({
  loader: () => import("./Pages/Table/SubAreas"),
  loading: Loading,
});
export const ManageOrders = Loadable({
  loader: () => import("./Pages/AllOrders/ManageOrders/ManageOrders"),
  loading: Loading,
});
export const ReturnRequests = Loadable({
  loader: () => import("./Pages/AllOrders/ReturnOrders/ReturnOrders"),
  loading: Loading,
});
export const SingleReturnRequest = Loadable({
  loader: () =>
    import(
      "./Pages/AllOrders/ReturnOrders/ReturnOrderDetails/ReturnOrderDetails"
    ),
  loading: Loading,
});
export const FindReturnRequest = Loadable({
  loader: () => import("./Pages/Forms/FindReturnRequest"),
  loading: Loading,
});
export const SingleOrder = Loadable({
  loader: () =>
    import("./Pages/AllOrders/ManageOrders/OrderDetails/OrderDetails"),
  loading: Loading,
});
export const MetaOrder = Loadable({
  loader: () => import("./Pages/Standalone/MetaOrder"),
  loading: Loading,
});
export const Products360 = Loadable({
  loader: () => import("./Pages/Table/Products360"),
  loading: Loading,
});
export const CreateTag = Loadable({
  loader: () => import("./Pages/Forms/CreateTag"),
  loading: Loading,
});
export const UpdateTag = Loadable({
  loader: () => import("./Pages/Forms/UpdateTag"),
  loading: Loading,
});
export const TagProducts = Loadable({
  loader: () => import("./Pages/Table/TagProducts"),
  loading: Loading,
});
export const CreateProduct = Loadable({
  loader: () => import("./Pages/CreateProduct/CreateProduct"),
  loading: Loading,
});
export const UpdateProduct = Loadable({
  // loader: () => import("./Pages/Forms/UpdateProduct"),
  loader: () => import("./Pages/CreateProduct/CreateProduct"),
  loading: Loading,
});
export const RelatedProducts = Loadable({
  loader: () => import("./Pages/Table/RelatedProducts"),
  loading: Loading,
});
export const RelatedCollections = Loadable({
  loader: () => import("./Pages/Table/RelatedCollections"),
  loading: Loading,
});
export const CreateCollection = Loadable({
  loader: () => import("./Pages/Forms/CreateCollection"),
  loading: Loading,
});
export const UpdateCollection = Loadable({
  loader: () => import("./Pages/Forms/UpdateCollection"),
  loading: Loading,
});
export const CollectionProducts = Loadable({
  loader: () => import("./Pages/Table/CollectionProducts"),
  loading: Loading,
});
export const ProductsImages = Loadable({
  loader: () => import("./Pages/Forms/ProductsImages"),
  loading: Loading,
});
export const CreateProductFeature = Loadable({
  loader: () => import("./Pages/Forms/ProductFeature"),
  loading: Loading,
});
export const ProductFeatures = Loadable({
  loader: () => import("./Pages/Table/ProductFeatures"),
  loading: Loading,
});
export const UpdateProductFeature = Loadable({
  loader: () => import("./Pages/Forms/UpdateProductFeature"),
  loading: Loading,
});
export const ThemesClasses = Loadable({
  loader: () => import("./Pages/Table/ThemesClasses"),
  loading: Loading,
});
export const CreateTheme = Loadable({
  loader: () => import("./Pages/Forms/CreateTheme"),
  loading: Loading,
});
export const Themes = Loadable({
  loader: () => import("./Pages/Listing/Themes"),
  loading: Loading,
});
export const UpdateTheme = Loadable({
  loader: () => import("./Pages/Forms/UpdateTheme"),
  loading: Loading,
});
export const OrganizationClasses = Loadable({
  loader: () => import("./Pages/Forms/OrganizationClasses"),
  loading: Loading,
});
export const OrganizationThemes = Loadable({
  loader: () => import("./Pages/Forms/OrganizationThemes"),
  loading: Loading,
});
export const CreatePromotion = Loadable({
  loader: () => import("./Pages/Forms/CreatePromotion"),
  loading: Loading,
});
export const UpdatePromotion = Loadable({
  loader: () => import("./Pages/Forms/UpdatePromotion"),
  loading: Loading,
});
export const ListPromotions = Loadable({
  loader: () => import("./Pages/Table/ListPromotions"),
  loading: Loading,
});
export const Reviews = Loadable({
  loader: () => import("./Pages/Table/Reviews"),
  loading: Loading,
});
export const Table = Loadable({
  loader: () => import("./Pages/Table/BasicTable"),
  loading: Loading,
});
export const Login = Loadable({
  loader: () => import("./Pages/Users/Login"),
  loading: Loading,
});
export const LoginDedicated = Loadable({
  loader: () => import("./Pages/Standalone/LoginDedicated"),
  loading: Loading,
});
export const Register = Loadable({
  loader: () => import("./Pages/Users/Register"),
  loading: Loading,
});
export const ForgotPassword = Loadable({
  loader: () => import("./Pages/Users/ForgotPassword"),
  loading: Loading,
});
export const NotFound = Loadable({
  loader: () => import("./NotFound/NotFound"),
  loading: Loading,
});
export const NotFoundDedicated = Loadable({
  loader: () => import("./Pages/Standalone/NotFoundDedicated"),
  loading: Loading,
});
export const Error = Loadable({
  loader: () => import("./Pages/Error"),
  loading: Loading,
});
export const Maintenance = Loadable({
  loader: () => import("./Pages/Maintenance"),
  loading: Loading,
});
export const ComingSoon = Loadable({
  loader: () => import("./Pages/ComingSoon"),
  loading: Loading,
});
export const Parent = Loadable({
  loader: () => import("./Parent"),
  loading: Loading,
});
export const Categories = Loadable({
  loader: () => import("./Pages/Listing/Categories/Categories"),
  loading: Loading,
});
export const VideoChat = Loadable({
  loader: () => import("./Pages/Table/VideoChat"),
  loading: Loading,
});
export const ProductVariants = Loadable({
  loader: () => import("./Pages/Table/ProductVariants"),
  loading: Loading,
});
export const UpdateVariant = Loadable({
  loader: () => import("./Pages/Forms/UpdateVariant"),
  loading: Loading,
});
export const UpdateUser = Loadable({
  loader: () => import("./Pages/Forms/UpdateUser"),
  loading: Loading,
});

// New pages
export const ScanQr = Loadable({
  loader: () => import("./Pages/ScanQR/ScanQr"),
  loading: Loading,
});
export const ScanQrDetails = Loadable({
  loader: () => import("./Pages/ScanQR/ScanQrDetails/ScanQrDetails"),
  loading: Loading,
});
export const ManageOwnersShopsRequests = Loadable({
  loader: () =>
    import(
      "./Pages/OwnersShopsRequests/OwnersShopsRequestsTable/OwnersShopsRequestsTable"
    ),
  loading: Loading,
});

export const ManageOwnerShopRequest = Loadable({
  loader: () =>
    import(
      "./Pages/OwnersShopsRequests/OwnerShopRequestDetails/OwnerShopRequestDetails"
    ),
  loading: Loading,
});
export const HotTagsSearch = Loadable({
  loader: () => import("./Pages/Listing/SearchAttributes/HotSearchTags"),
  loading: Loading,
});
export const StoreTagsSearch = Loadable({
  loader: () => import("./Pages/Listing/SearchAttributes/InStoreTags"),
  loading: Loading,
});
export const ManageInfluencers = Loadable({
  loader: () =>
    import("./Pages/Influencers/ManageInfluencers/ManageInfluencers"),
  loading: Loading,
});
export const InfluencerDetails = Loadable({
  loader: () =>
    import(
      "./Pages/Influencers/ManageInfluencers/InfluencerDetails/InfluencerDetails"
    ),
  loading: Loading,
});
export const InfluencerEventDetails = Loadable({
  loader: () =>
    import(
      "./Pages/Influencers/ManageInfluencers/InfluencerEventInfo/InfluencerEventInfo"
    ),
  loading: Loading,
});
export const InfluencersRequests = Loadable({
  loader: () =>
    import("./Pages/Influencers/InfluencersRequests/InfluencersRequests"),
  loading: Loading,
});
export const InfluencerRequestsDetails = Loadable({
  loader: () =>
    import(
      "./Pages/Influencers/InfluencerRequestsDetails/InfluencerRequestsDetails"
    ),
  loading: Loading,
});
export const CreateService = Loadable({
  loader: () => import("./Pages/Services/CreateService/CreateService"),
  loading: Loading,
});
export const ManageService = Loadable({
  loader: () => import("./Pages/Services/ManageService/ManageService"),
  loading: Loading,
});
export const UpdateService = Loadable({
  loader: () => import("./Pages/Services/UpdateService/UpdateService"),
  loading: Loading,
});
export const CreatePackage = Loadable({
  loader: () => import("./Pages/Packages/CreatePackage/CreatePackage"),
  loading: Loading,
});
export const ManagePackage = Loadable({
  loader: () => import("./Pages/Packages/ManagePackage/ManagePackage"),
  loading: Loading,
});
export const UpdatePackage = Loadable({
  loader: () => import("./Pages/Packages/UpdatePackage/UpdatePackage"),
  loading: Loading,
});
export const CustomerDetails = Loadable({
  loader: () => import("./Pages/Standalone/CustomerDetails/CustomerDetails"),
  loading: Loading,
});
export const UpdateAskForEvent = Loadable({
  loader: () =>
    import("./Pages/Metaverse/AskForEvent/UpdateAskForEvent/UpdateAskForEvent"),
  loading: Loading,
});
export const ManageEvents = Loadable({
  loader: () => import("./Pages/Metaverse/ManageEvents/ManageEvents"),
  loading: Loading,
});
export const EventDetails = Loadable({
  loader: () => import("./Pages/Metaverse/EventDetails/EventDetails"),
  loading: Loading,
});
export const AskForEvent = Loadable({
  loader: () => import("./Pages/Metaverse/AskForEvent/AskForEvent"),
  loading: Loading,
});
export const InfluencersEventsRequests = Loadable({
  loader: () =>
    import(
      "./Pages/Metaverse/InfluencersEventsRequests/InfluencersEventsRequests"
    ),
  loading: Loading,
});
export const InfluencerEventRequestDetails = Loadable({
  loader: () =>
    import(
      "./Pages/Metaverse/InfluencerEventRequestDetails/InfluencerEventRequestDetails"
    ),
  loading: Loading,
});
export const ManageCalls = Loadable({
  loader: () => import("./Pages/VideoCalls/ManageCalls/ManageCalls"),
  loading: Loading,
});
export const ManageQueue = Loadable({
  loader: () => import("./Pages/VideoCalls/ManageQueue/ManageQueue"),
  loading: Loading,
});
export const BookingCalls = Loadable({
  loader: () => import("./Pages/VideoCalls/BookingCalls/BookingCalls"),
  loading: Loading,
});
export const VideoCallsReviews = Loadable({
  loader: () =>
    import("./Pages/VideoCalls/VideoCallsReviews/VideoCallsReviews"),
  loading: Loading,
});
export const Availability = Loadable({
  loader: () => import("./Pages/VideoCalls/Availability/Availability"),
  loading: Loading,
});
export const SharedItems = Loadable({
  loader: () => import("./Pages/ForYou/SharedItems/SharedItems"),
  loading: Loading,
});
export const ReviewsRequests = Loadable({
  loader: () => import("./Pages/ForYou/ReviewsRequests/ReviewsRequests"),
  loading: Loading,
});
export const ReviewRequestDetails = Loadable({
  loader: () =>
    import(
      "./Pages/ForYou/ReviewsRequests/ReviewRequestDetails/ReviewRequestDetails"
    ),
  loading: Loading,
});
export const Receipts = Loadable({
  loader: () => import("./Pages/Receipts/ManageReceipts/ManageReceipts"),
  loading: Loading,
});
export const ReceiptDetails = Loadable({
  loader: () => import("./Pages/Receipts/ReceiptDetails/ReceiptDetails"),
  loading: Loading,
});
export const UserProfile = Loadable({
  loader: () => import("./Pages/CartCheckout/CartCheckout"),
  loading: Loading,
});

export const AddBranch = Loadable({
  loader: () => import("./Pages/OrganizationsBranches/AddBranch"),
  loading: Loading,
});

export const EditBranches = Loadable({
  loader: () => import("./Pages/OrganizationsBranches/EditBranches"),
  loading: Loading,
});

export const UpdateBranch = Loadable({
  loader: () => import("./Pages/OrganizationsBranches/UpdateBranch"),
  loading: Loading,
});

export const CustomersReviews = Loadable({
  loader: () => import("./Pages/OrganizationsBranches/CustomersReviews"),
  loading: Loading,
});

export const EditOrganization = Loadable({
  loader: () => import("./Pages/EditOrganization/EditOrganization"),
  loading: Loading,
});

export const UpdateOrganization = Loadable({
  loader: () => import("./Pages/Forms/UpdateOrganization"),
  loading: Loading,
});

export const AddAdvertisement = Loadable({
  loader: () => import("./Pages/Advertisements/AddAdvertisement"),
  loading: Loading,
});
export const UpdateAdvertisement = Loadable({
  loader: () => import("./Pages/Advertisements/UpdateAdvertisement"),
  loading: Loading,
});
export const Advertisements = Loadable({
  loader: () => import("./Pages/Advertisements/Advertisements/Advertisements"),
  loading: Loading,
});
export const ProductsFeatures = Loadable({
  loader: () => import("./Pages/ProductsFeatures/ProductsFeatures"),
  loading: Loading,
});
export const CreateStores = Loadable({
  loader: () => import("./Pages/360Stores/Create360Stores/Create360Stores"),
  loading: Loading,
});
export const EditStores = Loadable({
  loader: () => import("./Pages/360Stores/Manage360Stores/Manage360Stores"),
  loading: Loading,
});
export const UpdateStores = Loadable({
  loader: () => import("./Pages/360Stores/Update360Stores/Update360Stores"),
  loading: Loading,
});
export const UploadOrganizationImages = Loadable({
  loader: () =>
    import(
      "./Pages/Organization/UploadOrganizationImages/UploadOrganizationImages"
    ),
  loading: Loading,
});
export const PointsConfiguration = Loadable({
  loader: () =>
    import("./Pages/Points/PointsConfiguration/PointsConfiguration"),
  loading: Loading,
});
export const CreatePointsConfiguration = Loadable({
  loader: () =>
    import(
      "./Pages/Points/PointsConfiguration/CreatePointsConfiguration/CreatePointsConfiguration"
    ),
  loading: Loading,
});
export const UpdatePointsConfiguration = Loadable({
  loader: () =>
    import(
      "./Pages/Points/PointsConfiguration/UpdatePointsConfiguration/UpdatePointsConfiguration"
    ),
  loading: Loading,
});
export const ControlPoints = Loadable({
  loader: () => import("./Pages/Points/ControlPoints/ControlPoints"),
  loading: Loading,
});
export const CreateControlPoint = Loadable({
  loader: () =>
    import(
      "./Pages/Points/ControlPoints/CreateControlPoint/CreateControlPoint"
    ),
  loading: Loading,
});
export const UpdateControlPoint = Loadable({
  loader: () =>
    import(
      "./Pages/Points/ControlPoints/UpdateControlPoint/UpdateControlPoint"
    ),
  loading: Loading,
});
export const AddShippingService = Loadable({
  loader: () => import("./Pages/ShippingServices/AddService/AddService"),
  loading: Loading,
});
export const EditShippingServices = Loadable({
  loader: () => import("./Pages/ShippingServices/EditServices/EditServices"),
  loading: Loading,
});
export const UpdateShippingService = Loadable({
  loader: () => import("./Pages/ShippingServices/UpdateService/UpdateService"),
  loading: Loading,
});
export const MyProfile = Loadable({
  loader: () => import("./Pages/MyProfile/MyProfile"),
  loading: Loading,
});
export const ImportProductsV2 = Loadable({
  loader: () =>
    import("./Pages/CreateProduct/ImportProductsExcel/ImportProductsProvider"),
  loading: Loading,
});
export const WidgetSettings = Loadable({
  loader: () => import("./Pages/WidgetSettings/WidgetSettings"),
  loading: Loading,
});
export const CreateNewEvent = Loadable({
  loader: () => import("./Pages/Metaverse/MetaverseWrapper/MetaverseWrapper"),
  loading: Loading,
});
export const CreateCompensation = Loadable({
  loader: () =>
    import("./Pages/Compensation/CreateCompensation/CreateCompensation"),
  loading: Loading,
});
export const ManageCompensation = Loadable({
  loader: () =>
    import("./Pages/Compensation/ManageCompensation/ManageCompensation"),
  loading: Loading,
});
export const Add3DProduct = Loadable({
  loader: () => import("./Pages/Add3DProduct/Add3DProduct"),
  loading: Loading,
});
export const Manage3DProducts = Loadable({
  loader: () => import("./Pages/Manage3DProducts/Manage3DProducts"),
  loading: Loading,
});
export const Product3DDetails = Loadable({
  loader: () => import("./Pages/Product3DDetails/Product3DDetails"),
  loading: Loading,
});
export const Edit3DProduct = Loadable({
  loader: () => import("./Pages/Add3DProduct/Add3DProduct"),
  loading: Loading,
});
export const UserCheckout = Loadable({
  loader: () => import("./Pages/UserCheckout/UserCheckout"),
  loading: Loading,
});
export const BusinessInformation = Loadable({
  loader: () => import("./Pages/BusinessInformation/BusinessInformation"),
  loading: Loading,
});
